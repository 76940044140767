



















































import { Component, Vue } from 'vue-property-decorator'
import bulletTrain from 'bullet-train-client'

@Component
export default class TopToolbar extends Vue {
    private showMenu = false;
    private showDebug = false;
    private bulletTrain = bulletTrain.init({ environmentID: 'd2mHvsVzLfiNNBkq9YCSij', onChange: this.featureFlagChanged })

    toggleMenu (): void {
      this.showMenu = !this.showMenu
    }

    doNothing (): boolean {
      return false
    }

    private featureFlagChanged () {
      this.showDebug = bulletTrain.hasFeature('show-debug')
    }
}
