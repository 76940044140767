// profile/getters.ts
import { ProfileState } from './types'
import { GetterTree } from 'vuex'

import { RootState } from '../types'
import LogRocket from 'logrocket'
import bulletTrain from 'bullet-train-client'

export const getters: GetterTree<ProfileState, RootState> = {
  fullName (state): string {
    if (state.user && state.user.attributes) {
      const { attributes } = state.user
      const firstName = attributes.given_name ? attributes.given_name : ''
      const lastName = attributes.family_name ? attributes.family_name : ''
      return `${firstName} ${lastName}`
    }
    return ''
  },

  firstName (state): string {
    if (state.user && state.user.attributes) {
      const { attributes } = state.user
      return attributes.given_name ? attributes.given_name : ''
    }
    return ''
  },
  loggedIn (state): boolean {
    const user: any = state.user
    if (state.loggedIn) {
      LogRocket.identify('aspyr-media/appaspyrcom', {
        name: user.attributes.given_name + ' ' + user.attributes.family_name,
        email: user.attributes.email
      })
      bulletTrain.identify(user.attributes.email)
    }
    return state.loggedIn
  }
}
