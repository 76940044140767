


































import { Component, Vue } from 'vue-property-decorator'
import bulletTrain from 'bullet-train-client'
import { State, Action, Getter } from 'vuex-class'

const namespace = 'profile'

@Component
export default class BottomNav extends Vue {
  private activeItem = 'home';
  private showDebug = false;
  private showVDI = false;
  private nameUnderAccount = false;
  private bulletTrain = bulletTrain.init({ environmentID: 'd2mHvsVzLfiNNBkq9YCSij', onChange: this.featureFlagChanged })

  @Getter('loggedIn', { namespace })
  loggedIn!: boolean

  @Getter('firstName', { namespace })
  firstName!: string

  private featureFlagChanged () {
    this.showDebug = bulletTrain.hasFeature('show-debug')
    this.showVDI = bulletTrain.hasFeature('show-vdi')
    this.nameUnderAccount = bulletTrain.hasFeature('show-name-under-account')
  }
}
