import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import App from './App.vue'
import './registerServiceWorker.js'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Amplify from 'aws-amplify'
import '@aws-amplify/ui-vue'
import awsExports from './aws-exports.modifiers'

import LogRocket from 'logrocket'
import bulletTrain from 'bullet-train-client'

LogRocket.init('aspyr-media/appaspyrcom')

bulletTrain.init({
  environmentID: 'd2mHvsVzLfiNNBkq9YCSij'
})

Amplify.configure(awsExports)

Vue.config.productionTip = false
Vue.use(VueCompositionApi)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
