// profile/mutations.ts

import { MutationTree } from 'vuex'
import { ProfileState, User } from './types'

export const mutations: MutationTree<ProfileState> = {
  authStarted (state) {
    state.loggedIn = false
    state.status = 'loggingIn'
    state.user = undefined
  },
  authSuccess (state, payload: User) {
    state.loggedIn = true
    state.status = 'success'
    state.user = payload
  },
  authError (state) {
    state.loggedIn = false
    state.status = 'error'
    state.user = undefined
  },
  logout (state) {
    state.loggedIn = false
    state.status = ''
    state.user = undefined
  }
}
