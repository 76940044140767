/* eslint-disable @typescript-eslint/camelcase */
import aws_exports from './aws-exports'

// Select SignIn Redirect based on rendering doc's host names.
const signInRedirects = aws_exports.oauth.redirectSignIn.split(',')
if (signInRedirects.length > 1) {
  aws_exports.oauth.redirectSignIn = signInRedirects.filter(s => s.includes(document.location.host))[0]
}

// Select SignOut Redirect based on rendering doc's host names.
const signOutRedirects = aws_exports.oauth.redirectSignOut.split(',')
if (signOutRedirects.length > 1) {
  aws_exports.oauth.redirectSignOut = signInRedirects.filter(s => s.includes(document.location.host))[0]
}

console.log('AWS Settings', aws_exports)
export default aws_exports
