










import { Component, Vue } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { State, Action, Getter } from 'vuex-class'
import { ProfileState } from '@/store/profile/types'
import TopToolbar from '@/components/TopToolbar.vue'
import BottomNav from '@/components/BottomNav.vue'

const namespace: string = 'profile'

@Component({
  components: {
    TopToolbar,
    BottomNav
  }
})
export default class App extends Vue {
  public title = 'Aspyr App'
  private isLoggedIn: boolean = false;

  @State('profile')
  profile!: ProfileState;

  @Getter('loggedIn', { namespace })
  loggedIn!: boolean

  created () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.isLoggedIn = this.loggedIn
  }

  mounted () {
    // console.log('test', 'test2')
  }
}
