import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css'
// import { far } from "@fortawesome/free-regular-svg-icons"

Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(fas)

const CUSTOM_ICONS = {
  // add: { // custom icon I want to use
  //   component: FontAwesomeIcon,
  //   props: {
  //     icon: ['fad', 'plus']
  //   }
  // },
  menu: { // used for the nav-icon by vuetify
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'user']
    }
  }
}

export { CUSTOM_ICONS }
