/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:724f2a51-75bb-49c5-9cc7-6fddecb7b70e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_efox5qxxk",
    "aws_user_pools_web_client_id": "5q3dqa12ub24nedkk3ve1jom1v",
    "oauth": {
        "domain": "appaspyrcom241c5d0f-241c5d0f-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.aspyr.com/auth/,http://localhost:8080/auth/,https://dev-app.aspyr.com/auth/",
        "redirectSignOut": "https://app.aspyr.com/auth/,http://localhost:8080/auth/,https://dev-app.aspyr.com/auth/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
