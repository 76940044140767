import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'
import { profile } from './profile/index'
import createPersistedState from 'vuex-persistedstate'
import LogRocket from 'logrocket'
import createLogRocketPlugin from 'logrocket-vuex'

LogRocket.init('aspyr-media/appaspyrcom')
const logrocketPlugin = createLogRocketPlugin(LogRocket)

Vue.use(Vuex)

type authState = {
  status: string;
  token: string;
  user: object;
}

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0'
  },
  modules: {
    profile
  },
  plugins: [createPersistedState(), logrocketPlugin]
}
export default new Vuex.Store<RootState>(store)
