// profile/actions.ts

import { ActionTree } from 'vuex'
import { ProfileState } from './types'
import { RootState } from '../types'
import { Auth } from 'aws-amplify'
import LogRocket from 'logrocket'
import bulletTrain from 'bullet-train-client'

export const actions: ActionTree<ProfileState, RootState> = {
  ssoLogin ({ commit }) {
    commit('authStarted')
    Auth.federatedSignIn({ customProvider: 'AspyrJumpCloud' })
  },
  ssoLoggedIn ({ commit }) {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          commit('authSuccess', user)
          LogRocket.identify('aspyr-media/appaspyrcom', {
            name: user.attributes.given_name + ' ' + user.attributes.family_name,
            email: user.attributes.email
          })
          bulletTrain.identify(user.attributes.email)

          resolve(user)
        })
        .catch((err) => {
          console.log('Not signed in')
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      Auth.signOut()
        .then(data => {
          console.log('data', data)
          resolve()
        })
        .catch(err => {
          console.log('err', err)
          reject(err)
        })
    })
  }
}
